/* You can add global styles to this file, and also import other style files */
body{
    /* background-color: #424242; */
    font-family: 'Roboto', sans-serif;
margin: 0;
}


.sponsorLogo{
    max-width: 200px;
    max-height: 150px;
    width: auto;
    height: auto;
}

.sponsor{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
    justify-content: space-between;
    height: 100%;
}
 .sponsorsList{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 100px;
} 
.sponsorsList a{
    height: 200px;
    text-decoration: none;
    color: #1D5732;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 45px;;
}
.banner{
    max-width: 50vw;
}


#ResourceList{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.registerButton{
    position: relative;
    top: 20px;
    color: black;
    background-color: #A1FFC3;
    padding: 20px;
    border-radius: 20px;
    }
    .registerButton:hover{
        background-color: white;
    }
    

@media only screen and (max-width: 640px) {
.banner{
    max-width: 80vw;
}
.sponsorsList{
    grid-template-columns: auto;
}

}